import React from 'react'
import ReactPlayer from 'react-player/youtube'

import { Layout } from 'components/global'
import { Cast, PageHero } from 'components/blocks'

const TeamPage = () => {
  const meta = {
    title: `Team | Dorothy Dandridge`,
  }
  return (
    <Layout title={meta.title}>
      <PageHero title="Team" />
      <Cast />
      <div className="bg-black">
        <h3 className="text-white text-center pt-12 w-11/12 mx-auto">
          Everett Bradley – Welcome to My World
        </h3>
        <div className="w-11/12 mx-auto container pt-6 pb-12">
          <div className="border-4 border-[#FFC700] rounded shadow-md max-w-[1000px] mx-auto my-3 ">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=BcV8od2zm1w"
              width="100%"
              height="auto"
              className="rounded [&>iframe]:rounded aspect-video"
              // controls={false}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TeamPage
